// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-vehicle-move-js": () => import("./../src/templates/vehicleMove.js" /* webpackChunkName: "component---src-templates-vehicle-move-js" */),
  "component---src-templates-general-js": () => import("./../src/templates/general.js" /* webpackChunkName: "component---src-templates-general-js" */),
  "component---src-templates-careers-js": () => import("./../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

